
    import ThreeBackgroundMixin from '@/mixins/three-background-mixin';
    import * as THREE from 'three';
    import { Component, Mixins, Prop } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class ContactCard extends Mixins(ThreeBackgroundMixin) {
        // Properties
        @Prop() private readonly name!: string;
        @Prop() private readonly title!: string;
        @Prop() private readonly icon1!: string;
        @Prop() private readonly url1!: string;
        @Prop() private readonly icon2!: string;
        @Prop() private readonly url2!: string;

        // Methods -----------------------------------------------------------------------------------------------------
        private data() {
            return {
                linesImagePathAndName:
                    require("@/assets/images/contact_card_lines.png"),
            };
        }
    }

import { render, staticRenderFns } from "./my-education.vue?vue&type=template&id=ff5b1192&scoped=true&lang=pug"
import script from "./my-education.vue?vue&type=script&lang=ts"
export * from "./my-education.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff5b1192",
  null
  
)

export default component.exports
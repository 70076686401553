
    import ThreeBackgroundMixin from '@/mixins/three-background-mixin';
    import * as THREE from 'three';
    import { Component, Mixins, Prop } from 'vue-property-decorator';

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class Sheet3d extends Mixins(ThreeBackgroundMixin) {
        // Properties
        @Prop() private readonly styleString!: string;
        @Prop() private readonly iconPathAndName!: string;
        @Prop() private readonly heading!: string;
        @Prop() private readonly wireFrameColor!: string;

        // Readonly
        private readonly CORNER_RADIUS_1: number                            =   14;
        private readonly CORNER_RADIUS_2: number                            =   38;

        private readonly SPHERE_RADIUS: number                              =    2;

        private readonly SPHERE_WIDTH_SEGMENTS: number                      =   40;
        private readonly SPHERE_HEIGHT_SEGMENTS: number                     =   16;

        private readonly SPHERE_PHI_START: number                           =    0;
        private readonly SPHERE_PHI_LENGTH: number                          =  360;

        private readonly SPHERE_THETA_START: number                         =   30;
        private readonly SPHERE_THETA_LENGTH: number                        =  120;

        private readonly ANIMATION_YAW_PER_FRAME: number                    =    0.0002;

        public mounted() {
            this.setup(this.CORNER_RADIUS_1, this.CORNER_RADIUS_2);
            this.setupBackgroundMesh();
            this.setupScene();

            const selfRequestingRender = () => {
                this.animateBackgroundMesh();
                if (this.shouldResize()) {
                    this.resize(this.CORNER_RADIUS_1, this.CORNER_RADIUS_2);
                }
                if (this.threeJsRenderer != null &&
                    this.scene != null &&
                    this.camera != null) {
                    this.threeJsRenderer.render(this.scene, this.camera);
                    this.drawThreeJsCanvasOnToVueCanvas();
                }
                requestAnimationFrame(selfRequestingRender);
            };
            selfRequestingRender();     // Kick off the first call to selfRequestingRender()
        }

        // Implementation ----------------------------------------------------------------------------------------------
        private setupBackgroundMesh(): void {
            const geometry = new THREE.SphereGeometry(
                this.SPHERE_RADIUS,
                this.SPHERE_WIDTH_SEGMENTS,
                this.SPHERE_HEIGHT_SEGMENTS,
                THREE.Math.degToRad(this.SPHERE_PHI_START),
                THREE.Math.degToRad(this.SPHERE_PHI_LENGTH),
                THREE.Math.degToRad(this.SPHERE_THETA_START),
                THREE.Math.degToRad(this.SPHERE_THETA_LENGTH));

            const material      = new THREE.MeshBasicMaterial( { color: this.wireFrameColor, wireframe: true } );
            this.backgroundMesh = new THREE.Mesh(geometry, material);
        }

        private setupScene(): void {
            this.scene = new THREE.Scene();
            if (this.backgroundMesh != null) {
                this.scene.add(this.backgroundMesh);
            }
        }

        private animateBackgroundMesh(): void {
            if (this.backgroundMesh != null) {
                this.backgroundMesh.rotation.y += this.ANIMATION_YAW_PER_FRAME;
            }
        }
    }


    import VuePannellum from "@/components/sheets/sheet-pannellum.vue";
    Vue.component("VPannellum", VuePannellum);

    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {
            VuePannellum
        },
    })

    // *****************************************************************************************************************
    export default class SheetPhotosphere extends Vue {
        // Properties
        @Prop() private readonly url!: string;
    }

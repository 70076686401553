import Vue from "vue";
import VueRouter from "vue-router";

import WhoIAm from "../views/who_i_am.vue";

Vue.use(VueRouter);

// Route level code-splitting
// These generates a separate chunk (about.[hash].js) each route, which is lazy-loaded when the route is visited.
const routes = [
    // About ***********************************************************************************************************
    {
        component:  WhoIAm,
        name:       "WhoIAm",
        path:       "/",
    },
    // About2 ----------------------------------------------------------------------------------------------------------
    {
        component:  () => import(
            /* webpackChunkName: "about_leadership" */
            "../views/about/about_leadership.vue"),
        name:       "AboutLeadership",
        path:       "/about_leadership",
    },
    {
        component:  () => import(
            /* webpackChunkName: "about_my_management" */
            "../views/about/about_my_management.vue"),
        name:       "AboutMyManagement",
        path:       "/about_my_management",
    },
    {
        component:  () => import(
            /* webpackChunkName: "about_my_skills" */
            "../views/about/about_my_skills.vue"),
        name:       "AboutMySkills",
        path:       "/about_my_skills",
    },
    {
        component:  () => import(
            /* webpackChunkName: "about_my_experience" */
            "../views/about/about_my_experience.vue"),
        name:       "AboutMyExperience",
        path:       "/about_my_experience",
    },
    {
        component:  () => import(
            /* webpackChunkName: "about_my_references" */
            "../views/about/about_my_references.vue"),
        name:       "AboutMyReferences",
        path:       "/about_my_references",
    },
    // About3 ----------------------------------------------------------------------------------------------------------
    {
        component:  () => import(
            /* webpackChunkName: "about_my_sports" */
            "../views/about/about_my_sports.vue"),
        name:       "AboutMySports",
        path:       "/about_my_sports",
    },

    // Books1 **********************************************************************************************************
    {
        component:  () => import(
            /* webpackChunkName: "books_awesome_list" */
            "../views/books/books_awesome_list.vue"),
        name:       "BooksAwesomeList",
        path:       "/books_awesome_list",
    },
    {
        component:  () => import(
            /* webpackChunkName: "books_code_complete" */
            "../views/books/books_code_complete.vue"),
        name:       "BooksCodeComplete",
        path:       "/books_code_complete",
    },
    {
        component:  () => import(
            /* webpackChunkName: "books_design_patterns" */
            "../views/books/books_design_patterns.vue"),
        name:       "BooksDesignPatterns",
        path:       "/books_design_patterns",
    },
    {
        component:  () => import(
            /* webpackChunkName: "books_joy_inc" */
            "../views/books/books_joy_inc.vue"),
        name:       "BooksJoyInc",
        path:       "/books_joy_inc",
    },
    {
        component:  () => import(
            /* webpackChunkName: "books_radical_candor" */
            "../views/books/books_radical_candor.vue"),
        name:       "BooksRadicalCandor",
        path:       "/books_radical_candor",
    },
    {
        component:  () => import(
            /* webpackChunkName: "books_refactoring" */
            "../views/books/books_refactoring.vue"),
        name:       "BooksRefactoring",
        path:       "/books_refactoring",
    },
    {
        component:  () => import(
            /* webpackChunkName: "books_chimp_paradox" */
            "../views/books/books_chimp_paradox.vue"),
        name:       "BooksTheChimpParadox",
        path:       "/books_chimp_paradox",
    },
    {
        component:  () => import(
            /* webpackChunkName: "books_the_lean_startup" */
            "../views/books/books_the_lean_startup.vue"),
        name:       "BooksTheLeanStartup",
        path:       "/books_the_lean_startup",
    },
    {
        component:  () => import(
            /* webpackChunkName: "books_the_mythical_man_month" */
            "../views/books/books_the_mythical_man_month.vue"),
        name:       "BooksTheMythicalManMonth",
        path:       "/books_the_mythical_man_month",
    },
    {
        component:  () => import(
            /* webpackChunkName: "books_the_pragmatic_programmer" */
            "../views/books/books_the_pragmatic_programmer.vue"),
        name:       "BooksThePragmaticProgrammer",
        path:       "/books_the_pragmatic_programmer",
    },

    // Dev1 ************************************************************************************************************
    {
        component:  () => import(
            /* webpackChunkName: "dev_installation_list" */
            "../views/dev/dev_installation_list.vue"),
        name:       "DevInstallationList",
        path:       "/dev_installation_list",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_visual_studio_code_extensions_and_settings" */
            "../views/dev/dev_visual_studio_code_extensions_and_settings.vue"),
        name:       "DevVisualStudioCodeExtensionsAndSettings",
        path:       "/dev_visual_studio_code_extensions_and_settings",
    },
    // Dev2 ------------------------------------------------------------------------------------------------------------
    {
        component:  () => import(
            /* webpackChunkName: "dev_frontend" */
            "../views/dev/dev_frontend.vue"),
        name:       "DevFrontend",
        path:       "/dev_frontend",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_http" */
            "../views/dev/dev_http.vue"),
        name:       "DevHttp",
        path:       "/dev_http",
    },
    // Dev3 ------------------------------------------------------------------------------------------------------------
    {
        component:  () => import(
            /* webpackChunkName: "dev_backend" */
            "../views/dev/dev_backend.vue"),
        name:       "DevBackend",
        path:       "/dev_backend",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_aws" */
            "../views/dev/dev_aws.vue"),
        name:       "DevAws",
        path:       "/dev_aws",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_apis" */
            "../views/dev/dev_apis.vue"),
        name:       "DevAPIs",
        path:       "/dev_apis",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_databases" */
            "../views/dev/dev_databases.vue"),
        name:       "DevDatabases",
        path:       "/dev_databases",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_docker" */
            "../views/dev/dev_docker.vue"),
        name:       "DevDocker",
        path:       "/dev_docker",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_rest" */
            "../views/dev/dev_rest.vue"),
        name:       "DevRest",
        path:       "/dev_rest",
    },
    // Dev4 ------------------------------------------------------------------------------------------------------------
    {
        component:  () => import(
            /* webpackChunkName: "dev_algorithms" */
            "../views/dev/dev_algorithms.vue"),
        name:       "DevAlgorithms",
        path:       "/dev_algorithms",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_data_structures" */
            "../views/dev/dev_data_structures.vue"),
        name:       "DevDataStructures",
        path:       "/dev_data_structures",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_design_patterns" */
            "../views/dev/dev_design_patterns.vue"),
        name:       "DevDesignPatterns",
        path:       "/dev_design_patterns",
    },
    // Dev5 ------------------------------------------------------------------------------------------------------------
    {
        component:  () => import(
            /* webpackChunkName: "dev_c_plus_plus" */
            "../views/dev/dev_c_plus_plus.vue"),
        name:       "DevCPlusPlus",
        path:       "/dev_c_plus_plus",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_c_plus_plus_quizzes" */
            "../views/dev/dev_c_plus_plus_quizzes.vue"),
        name:       "DevCPlusPlusQuizzes",
        path:       "/dev_c_plus_plus_quizzes",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_c_sharp" */
            "../views/dev/dev_c_sharp.vue"),
        name:       "DevCSharp",
        path:       "/dev_c_sharp",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_c_sharp_quizzes" */
            "../views/dev/dev_c_sharp_quizzes.vue"),
        name:       "DevCSharpQuizzes",
        path:       "/dev_c_sharp_quizzes",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_typescript" */
            "../views/dev/dev_typescript.vue"),
        name:       "DevTypeScript",
        path:       "/dev_typescript",
    },
    // Dev6 ------------------------------------------------------------------------------------------------------------
    {
        component:  () => import(
            /* webpackChunkName: "dev_unity_bugs" */
            "../views/dev/dev_unity_bugs.vue"),
        name:       "DevUnityBugs",
        path:       "/dev_unity_bugs",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_unity_district_documentation" */
            "../views/dev/dev_unity_district_documentation.vue"),
        name:       "DevUnityDistrictDocumentation",
        path:       "/dev_unity_district_documentation",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_unity_district_limbo" */
            "../views/dev/dev_unity_district_limbo.vue"),
        name:       "DevUnityDistrictLimbo",
        path:       "/dev_unity_district_limbo",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_unity_district_map_room" */
            "../views/dev/dev_unity_district_map_room.vue"),
        name:       "DevUnityDistrictMapRoom",
        path:       "/dev_unity_district_map_room",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_unity_links" */
            "../views/dev/dev_unity_links.vue"),
        name:       "DevUnityLinks",
        path:       "/dev_unity_links",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_unity_media" */
            "../views/dev/dev_unity_media.vue"),
        name:       "DevUnityMedia",
        path:       "/dev_unity_media",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_unity_optimizations" */
            "../views/dev/dev_unity_optimizations.vue"),
        name:       "DevUnityOptimizations",
        path:       "/dev_unity_optimizations",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_unity_quizzes" */
            "../views/dev/dev_unity_quizzes.vue"),
        name:       "DevUnityQuizzes",
        path:       "/dev_unity_quizzes",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_unity_quest_projects" */
            "../views/dev/dev_unity_quest_projects.vue"),
        name:       "DevUnityQuestProjects",
        path:       "/dev_unity_quest_projects",
    },
    // Dev7 ------------------------------------------------------------------------------------------------------------
    {
        component:  () => import(
            /* webpackChunkName: "dev_unreal_blueprints" */
            "../views/dev/dev_unreal_blueprints.vue"),
        name:       "DevUnrealBluePrints",
        path:       "/dev_unreal_blueprints",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_unreal_landscapes_and_open_worlds" */
            "../views/dev/dev_unreal_landscapes_and_open_worlds.vue"),
        name:       "DevUnrealLandscapesAndOpenWorlds",
        path:       "/dev_unreal_landscapes_and_open_worlds",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_unreal_materials" */
            "../views/dev/dev_unreal_materials.vue"),
        name:       "DevUnrealMaterials",
        path:       "/dev_unreal_materials",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_unreal_skeletal_meshes_and_animations" */
            "../views/dev/dev_unreal_skeletal_meshes_and_animations.vue"),
        name:       "DevUnrealSkeletalMeshesAndAnimations",
        path:       "/dev_unreal_skeletal_meshes_and_animations",
    },
    // Dev8 ------------------------------------------------------------------------------------------------------------
    {
        component:  () => import(
            /* webpackChunkName: "dev_gameplay" */
            "../views/dev/dev_gameplay.vue"),
        name:       "DevGameplay",
        path:       "/dev_gameplay",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_hardware" */
            "../views/dev/dev_hardware.vue"),
        name:       "DevHardware",
        path:       "/dev_hardware",
    },
    {
        component:  () => import(
            /* webpackChunkName: "dev_oculus_app_lab" */
            "../views/dev/dev_oculus_app_lab.vue"),
        name:       "DevOculusAppLab",
        path:       "/dev_oculus_app_lab",
    },
    // District ********************************************************************************************************
    {
        component:  () => import(
            /* webpackChunkName: "district" */
            "../views/district/district.vue"),
        name:       "District",
        path:       "/district",
    },
    {
        component:  () => import(
            /* webpackChunkName: "district_privacy_policy" */
            "../views/district/district-privacy-policy.vue"),
        name:       "DistrictPrivacyPolicy",
        path:       "/district-privacy-policy",
    },
    {
        component:  () => import(
            /* webpackChunkName: "district_terms_and_conditions" */
            "../views/district/district-terms-and-conditions.vue"),
        name:       "DistrictTermsAndConditions",
        path:       "/district-terms-and-conditions",
    },

    // Drones **********************************************************************************************************
    {
        component:  () => import(
            /* webpackChunkName: "drones_hardware_4g" */
            "../views/drones/drones_hardware_4g.vue"),
        name:       "DronesHardware4G",
        path:       "/drones_hardware_4g",
    },
    {
        component:  () => import(
            /* webpackChunkName: "drones_fpv_footage" */
            "../views/drones/drones_fpv_footage.vue"),
        name:       "DronesFPVFootage",
        path:       "/drones_fpv_footage",
    },
    {
        component:  () => import(
            /* webpackChunkName: "drones_hardware_concept" */
            "../views/drones/drones_hardware_concept.vue"),
        name:       "DronesHardwareConcept",
        path:       "/drones_hardware_concept",
    },
    {
        component:  () => import(
            /* webpackChunkName: "drones_hardware_passenger" */
            "../views/drones/drones_hardware_passenger.vue"),
        name:       "DronesHardwarePassenger",
        path:       "/drones_hardware_passenger",
    },
    {
        component:  () => import(
            /* webpackChunkName: "drones_hardware_small" */
            "../views/drones/drones_hardware_small.vue"),
        name:       "DronesHardwareSmall",
        path:       "/drones_hardware_small",
    },
    {
        component:  () => import(
            /* webpackChunkName: "drones_locations_grand_union_crescent_01" */
            "../views/drones/drones_locations_grand_union_crescent_01.vue"),
        name:       "DronesLocationsGrandUnionCrescent01",
        path:       "/drones_locations_grand_union_crescent_01",
    },
    {
        component:  () => import(
            /* webpackChunkName: "drones_locations_grand_union_crescent_02" */
            "../views/drones/drones_locations_grand_union_crescent_02.vue"),
        name:       "DronesLocationsGrandUnionCrescent02",
        path:       "/drones_locations_grand_union_crescent_02",
    },
    {
        component:  () => import(
            /* webpackChunkName: "drones_locations_hackney_marshes_01" */
            "../views/drones/drones_locations_hackney_marshes_01.vue"),
        name:       "DronesLocationsHackneyMarshes01",
        path:       "/drones_locations_hackney_marshes_01",
    },
    {
        component:  () => import(
            /* webpackChunkName: "drones_locations_haggerston_01" */
            "../views/drones/drones_locations_haggerston_01.vue"),
        name:       "DronesLocationsHaggerston01",
        path:       "/drones_locations_haggerston_01",
    },
    {
        component:  () => import(
            /* webpackChunkName: "drones_locations_london_fields_01" */
            "../views/drones/drones_locations_london_fields_01.vue"),
        name:       "DronesLocationsLondonFields01",
        path:       "/drones_locations_london_fields_01",
    },
    {
        component:  () => import(
            /* webpackChunkName: "drones_locations_london_fields_02" */
            "../views/drones/drones_locations_london_fields_02.vue"),
        name:       "DronesLocationsLondonFields02",
        path:       "/drones_locations_london_fields_02",
    },
    {
        component:  () => import(
            /* webpackChunkName: "drones_locations_shoreditch_01" */
            "../views/drones/drones_locations_shoreditch_01.vue"),
        name:       "DronesLocationsShoreditch01",
        path:       "/drones_locations_shoreditch_01",
    },
    {
        component:  () => import(
            /* webpackChunkName: "drones_locations_shoreditch_02" */
            "../views/drones/drones_locations_shoreditch_02.vue"),
        name:       "DronesLocationsShoreditch02",
        path:       "/drones_locations_shoreditch_02",
    },
    {
        component:  () => import(
            /* webpackChunkName: "drones_locations_tenby_01" */
            "../views/drones/drones_locations_tenby_01.vue"),
        name:       "DronesLocationsTenby01",
        path:       "/drones_locations_tenby_01",
    },
    {
        component:  () => import(
            /* webpackChunkName: "drones_locations_tenby_02" */
            "../views/drones/drones_locations_tenby_02.vue"),
        name:       "DronesLocationsTenby02",
        path:       "/drones_locations_tenby_02",
    },
    {
        component:  () => import(
            /* webpackChunkName: "drones_locations_victoria_park_01" */
            "../views/drones/drones_locations_victoria_park_01.vue"),
        name:       "DronesLocationsVictoriaPark01",
        path:       "/drones_locations_victoria_park_01",
    },

    // Economy *********************************************************************************************************
    {
        component:  () => import(
            /* webpackChunkName: "economy_economy" */
            "../views/economy/economy_economy.vue"),
        name:       "EconomyEconomy",
        path:       "/economy_economy",
    },
    {
        component:  () => import(
            /* webpackChunkName: "economy_cryptocurrencies" */
            "../views/economy/economy_cryptocurrencies.vue"),
        name:       "EconomyCryptocurrencies",
        path:       "/economy_cryptocurrencies",
    },
    {
        component:  () => import(
            /* webpackChunkName: "economy_hs2" */
            "../views/economy/economy_hs2.vue"),
        name:       "EconomyHS2",
        path:       "/economy_hs2",
    },
    {
        component:  () => import(
            /* webpackChunkName: "economy_precious_metals" */
            "../views/economy/economy_precious_metals.vue"),
        name:       "EconomyPreciousMetals",
        path:       "/economy_precious_metals",
    },
    {
        component:  () => import(
            /* webpackChunkName: "economy_property" */
            "../views/economy/economy_property.vue"),
        name:       "EconomyProperty",
        path:       "/economy_property",
    },
    {
        component:  () => import(
            /* webpackChunkName: "economy_stock_market" */
            "../views/economy/economy_stock_market.vue"),
        name:       "EconomyStockMarket",
        path:       "/economy_stock_market",
    },

    // Future **********************************************************************************************************
    {
        component:  () => import(
            /* webpackChunkName: "future_automation" */
            "../views/future/future_automation.vue"),
        name:       "FutureAutomation",
        path:       "/future_automation",
    },
    {
        component:  () => import(
            /* webpackChunkName: "future_covid_19" */
            "../views/future/future_covid_19.vue"),
        name:       "FutureCovid19",
        path:       "/future_covid_19",
    },
    {
        component:  () => import(
            /* webpackChunkName: "future_space" */
            "../views/future/future_space.vue"),
        name:       "FutureSpace",
        path:       "/future_space",
    },
    {
        component:  () => import(
            /* webpackChunkName: "future_telepresence" */
            "../views/future/future_telepresence.vue"),
        name:       "FutureTelepresence",
        path:       "/future_telepresence",
    },
    {
        component:  () => import(
            /* webpackChunkName: "future_tokamak_fusion" */
            "../views/future/future_tokamak_fusion.vue"),
        name:       "FutureTokamakFusion",
        path:       "/future_tokamak_fusion",
    },

    // People **********************************************************************************************************
    {
        component:  () => import(
            /* webpackChunkName: "people_hierarchy_of_needs" */
            "../views/people/people_hierarchy_of_needs.vue"),
        name:       "PeopleHierarchyOfNeeds",
        path:       "/people_hierarchy_of_needs",
    },
    {
        component:  () => import(
            /* webpackChunkName: "people_population_and_housing" */
            "../views/people/people_population_and_housing.vue"),
        name:       "PeoplePopulationAndHousing",
        path:       "/people_population_and_housing",
    },
    {
        component:  () => import(
            /* webpackChunkName: "people_remuneration_and_tax" */
            "../views/people/people_remuneration_and_tax.vue"),
        name:       "PeopleRemunerationAndTax",
        path:       "/people_remuneration_and_tax",
    },

    // XR **************************************************************************************************************
    // XR1 -------------------------------------------------------------------------------------------------------------
    {
        component:  () => import(
            /* webpackChunkName: "xr_configurations" */
            "../views/xr/xr_configurations.vue"),
        name:       "XrConfigurations",
        path:       "/xr_configurations",
    },
    {
        component:  () => import(
            /* webpackChunkName: "xr_headsets" */
            "../views/xr/xr_headsets.vue"),
        name:       "XrHeadsets",
        path:       "/xr_headsets",
    },
    // XR2 -------------------------------------------------------------------------------------------------------------
    {
        component:  () => import(
            /* webpackChunkName: "xr_locations_district_01" */
            "../views/xr/xr_locations_district_01.vue"),
        name:       "xrLocationsDistrict01",
        path:       "/xr_locations_district_01",
    },
    {
        component:  () => import(
            /* webpackChunkName: "xr_locations_district_02" */
            "../views/xr/xr_locations_district_02.vue"),
        name:       "xrLocationsDistrict02",
        path:       "/xr_locations_district_02",
    },
    // XR3 -------------------------------------------------------------------------------------------------------------
    {
        component:  () => import(
            /* webpackChunkName: "xr_foveated_rendering" */
            "../views/xr/xr_foveated_rendering.vue"),
        name:       "XrFoveatedRendering",
        path:       "/xr_foveated_rendering",
    },
    {
        component:  () => import(
            /* webpackChunkName: "xr_immersion" */
            "../views/xr/xr_immersion.vue"),
        name:       "XrImmersion",
        path:       "/xr_immersion",
    },
    {
        component:  () => import(
            /* webpackChunkName: "xr_photospheres" */
            "../views/xr/xr_photospheres.vue"),
        name:       "XrPhotospheres",
        path:       "/xr_photospheres",
    },
    {
        component:  () => import(
            /* webpackChunkName: "xr_waveguides" */
            "../views/xr/xr_waveguides.vue"),
        name:       "XrWaveGuides",
        path:       "/xr_waveguides",
    },
    // XR4 -------------------------------------------------------------------------------------------------------------
    {
        component:  () => import(
            /* webpackChunkName: "xr_my_articles" */
            "../views/xr/xr_my_articles.vue"),
        name:       "XrMyArticles",
        path:       "/xr_my_articles",
    },
    {
        component:  () => import(
            /* webpackChunkName: "xr_operating_systems" */
            "../views/xr/xr_operating_systems.vue"),
        name:       "XrOperatingSystems",
        path:       "/xr_operating_systems",
    },
    {
        component:  () => import(
            /* webpackChunkName: "xr_timeline" */
            "../views/xr/xr_timeline.vue"),
        name:       "XrTimeline",
        path:       "/xr_timeline",
    },
];

// *********************************************************************************************************************
const router = new VueRouter({
    routes,
});

export default router;


    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class NavigationList extends Vue {
        // Properties
        @Prop() private readonly items!: object[];
    }


    import ContactCard from "@/components/contact-card.vue";
    import HexagonsSeven from '@/components/hexagons/hexagons-seven.vue';
    import MyEducation from "@/components/my-education.vue";
    import MySkillsMap from "@/components/my-skills-map.vue";
    import MyToolsCarousel from "@/components/my-tools-carousel.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            ContactCard,
            HexagonsSeven,
            MyEducation,
            MySkillsMap,
            MyToolsCarousel,
        },
    })

    // *****************************************************************************************************************
    export default class WhoIAm extends Vue {
        // Data function
        public data() {
            return {
                educationIconPathAndName:
                    require("@/assets/images/icon_education.png"),

                toolsIconPathAndName:
                    require("@/assets/images/icon_tools.png"),

                whoIAmIconPathAndName:
                    require("@/assets/images/icon_individual_contributor.png"),
            };
        }
    }

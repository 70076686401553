
    import { Component, Vue } from 'vue-property-decorator';

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class AppBar extends Vue {
        // Data function
        private data() {
            return {
                backgroundImagePathAndName:
                    require("@/assets/images/finished.png"),

                appBarBackgroundImagePathAndName:
                    require("@/assets/images/app_bar_background.png"),
            };
        }

        // Methods -----------------------------------------------------------------------------------------------------
        private isDistrict(): boolean {
            const routeName = this.$route.name;

            switch (routeName) {
            case "District":
            case "DistrictPrivacyPolicy":
            case "DistrictTermsAndConditions":
                return true;
            default:
                return false;
            }
        }
    }

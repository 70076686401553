import "./scss/custom.scss";

import Vue from "vue";

// PrismJS related -----------------------------------------------------------------------------------------------------
import "prismjs";

import "prismjs/components/prism-bash.js";
import "prismjs/components/prism-c.js";
import "prismjs/components/prism-cpp.js";
import "prismjs/components/prism-csharp.js";
import "prismjs/components/prism-css.js";
import "prismjs/components/prism-git.js";
import "prismjs/components/prism-javascript.js";
import "prismjs/components/prism-json.js";
import "prismjs/components/prism-markup.js";
import "prismjs/components/prism-python.min";
import "prismjs/components/prism-sass.min";
import "prismjs/components/prism-scss.min";
import "prismjs/components/prism-typescript.js";

import "prismjs/plugins/autolinker/prism-autolinker.css";
import "prismjs/plugins/autolinker/prism-autolinker.min";

import "prismjs/themes/prism-okaidia.css";

import "vue-prism-editor/dist/VuePrismEditor.css";

// Import Common components, that we wish to be included by default, everywhere ----------------------------------------
import { Carousel3d, Slide } from 'vue-carousel-3d';

import Heading from "@/components/heading.vue";

import Sheet3d from "@/components/sheets/sheet-3d.vue";
import SheetGraph from "@/components/sheets/sheet-graph.vue";
import SheetImage from "@/components/sheets/sheet-image.vue";
import SheetMap from "@/components/sheets/sheet-map.vue";
import SheetPhotosphere from "@/components/sheets/sheet-photosphere.vue";
import SheetVideo from "@/components/sheets/sheet-video.vue";
import Sheet from "@/components/sheets/sheet.vue";

import SourceCode from "@/components/source-code.vue";

import TextCard from "@/components/text-card.vue";

// Name Common Components, that we wish to be included by default, everywhere ------------------------------------------
Vue.component("Carousel3d",       Carousel3d);
Vue.component("Slide",            Slide);

Vue.component("Heading",          Heading);

Vue.component("Sheet",            Sheet);
Vue.component("Sheet3d",          Sheet3d);
Vue.component("SheetGraph",       SheetGraph);
Vue.component("SheetImage",       SheetImage);
Vue.component("SheetMap",         SheetMap);
Vue.component("SheetPhotosphere", SheetPhotosphere);
Vue.component("SheetVideo",       SheetVideo);

Vue.component("SourceCode",       SourceCode);

Vue.component("TextCard",         TextCard);

// VueMq ---------------------------------------------------------------------------------------------------------------
import VueMq from 'vue-mq';

Vue.use(VueMq, {
    // tslint:disable
    breakpoints: { 
        mobile: 639,
        tablet: 900,
        laptop: 1250,
        lg: Infinity,
    },
    defaultBreakpoint: 'sm' // Customize this for SSR
    // tslint:enable
});

// VueKatex ------------------------------------------------------------------------------------------------------------
import 'katex/dist/katex.min.css';
import VueKatex from 'vue-katex';

Vue.use(VueKatex, {
    globalOptions: {
        // Set options here
    }
});

// VueGoogleMaps -------------------------------------------------------------------------------------------------------
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBMzQ9Jf2ceK-pmXhBZH3ShEuj4Te26jp0',
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        // If you want to set the version, you can do so:
        // v: '3.26',
    },

    // If you intend to programmatically custom event listener code
    // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    // you might need to turn this on.
    // autobindAllEvents: false,

    // If you want to manually install components, e.g.
    // import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    // Vue.component('GmapMarker', GmapMarker)
    // then disable the following:
    installComponents: true,
});

Vue.config.productionTip = false;

// *********************************************************************************************************************
// The Vue instance itself
import App from "./App.vue";
import vuetify from './plugins/vuetify';
import router from "./router";
import store from "./store";

Vue.use(vuetify);

new Vue({
    render: (h) => h(App),
    router,
    store,
    vuetify
}).$mount("#app");

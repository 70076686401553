
    import VuePrismEditor from "vue-prism-editor";
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {
            VuePrismEditor,
        },
    })

    // *****************************************************************************************************************
    export default class SourceCode extends Vue {
        // Properties --------------------------------------------------------------------------------------------------
        @Prop() private readonly code!: string;
        @Prop() private readonly codeLanguage!: string;
    }

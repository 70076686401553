
    // @ is an alias to /src
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class HexagonsThree extends Vue {
        // Properties
        @Prop() private readonly styleString!: string;
        @Prop() private readonly image1!: string;
        @Prop() private readonly label1!: string;
        @Prop() private readonly image2!: string;
        @Prop() private readonly label2!: string;
        @Prop() private readonly image3!: string;
        @Prop() private readonly label3!: string;
    }

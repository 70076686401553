
    // @ is an alias to /src
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class HexagonsSeven extends Vue {
        // Properties
        @Prop() private readonly styleString!: string;

        @Prop() private readonly color1!: string;
        @Prop() private readonly color2!: string;

        @Prop() private readonly title1!: string;
        @Prop() private readonly title2!: string;
        @Prop() private readonly title3!: string;
        @Prop() private readonly title4!: string;
        @Prop() private readonly title5!: string;
        @Prop() private readonly title6!: string;
        @Prop() private readonly title7!: string;
    }

import { render, staticRenderFns } from "./sheet-photosphere.vue?vue&type=template&id=cecb5582&scoped=true&lang=pug"
import script from "./sheet-photosphere.vue?vue&type=script&lang=ts"
export * from "./sheet-photosphere.vue?vue&type=script&lang=ts"
import style0 from "./sheet-photosphere.vue?vue&type=style&index=0&id=cecb5582&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cecb5582",
  null
  
)

export default component.exports
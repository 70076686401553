
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class CardWithLighting extends Vue {
        private readonly links = [
            { icon: 'fab fa-linkedin',      url: 'https://www.linkedin.com/in/danieldobson/' },
            { icon: 'fab fa-github',        url: 'https://github.com/Daniel-Dobson' },
        ];
    }


    import { Plotly } from 'vue-plotly';
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {
            Plotly,
        },
    })

    // *****************************************************************************************************************
    export default class SheetGraph extends Vue {
        // Properties
        @Prop() private readonly graph!: object;
        @Prop() private readonly layout!: object;

        // Fields
        private readonly options = {};
    }

import * as THREE from 'three';
import { Component, Vue } from 'vue-property-decorator';

// *********************************************************************************************************************
@Component
class ThreeBackgroundMixin extends Vue {
    public projName: string = 'My project';

    // Readonly
    protected readonly CAMERA_FIELD_OF_VIEW: number                     =   75;

    protected readonly CAMERA_NEAR_PLANE: number                        =    0.1;
    protected readonly CAMERA_FAR_PLANE: number                         = 1000;

    protected readonly CAMERA_POSITION_Z: number                        =    1;

    // Protected
    protected scene: THREE.Scene | null                                 = null;
    protected vueContext2d: CanvasRenderingContext2D | null             = null;
    protected camera: THREE.Camera | null                               = null;
    protected threeJsRenderer: THREE.WebGLRenderer | null               = null;

    protected shaderMaterial: THREE.ShaderMaterial                      = new THREE.ShaderMaterial();
    protected backgroundMesh: THREE.Mesh | null                         = null;

    protected haveSetupClipping: boolean                                = false;

    // Methods
    public setProjectName(newVal: string): void {
        this.projName = newVal;
    }

    // Implementation
    protected initializeShaderMaterial(shader: THREE.Shader): void {
        this.shaderMaterial = new THREE.ShaderMaterial({
            fragmentShader: shader.fragmentShader,
            uniforms:       shader.uniforms,
            vertexShader:   shader.vertexShader,
        });
    }

    protected drawThreeJsCanvasOnToVueCanvas(): void {
        const width     = this.getWidth();
        const height    = this.getHeight();

        if (this.vueContext2d != null &&
            this.threeJsRenderer != null) {

            this.vueContext2d.clearRect(0, 0, width, height);

            const threeJsCanvas = this.threeJsRenderer.getContext().canvas;
            this.vueContext2d.drawImage(threeJsCanvas, 0, 0);
        }
    }

    protected resize(cornerRadius1: number, cornerRadius2: number): void {
        this.setCanvasDimensions();                             // Update the canvas dimensions
        this.setupVueContext2d(cornerRadius1, cornerRadius2);   // Update the clipping in the Context2d
        this.setupCamera();                                     // Update the aspect ratio
        this.setupThreeJsRenderer();                            // Update the renderer size
    }

    protected setup(cornerRadius1: number, cornerRadius2: number): void {
        this.setCanvasDimensions();
        this.setupVueContext2d(cornerRadius1, cornerRadius2);
        this.setupCamera();
        this.setupThreeJsRenderer();
    }

    protected shouldResize(): boolean {
        const correctWidth: boolean     = this.getCanvasWidth() === this.getWidth();
        const correctHeight: boolean    = this.getCanvasHeight() === this.getHeight();

        return (!correctWidth || !correctHeight);
    }

    protected getWidth(): number {
        const innerDiv: HTMLDivElement|null = (this.$refs.innerDiv as HTMLDivElement);
        return (innerDiv != null) ?    innerDiv.offsetWidth : window.innerWidth;
    }

    protected getHeight(): number {
        const innerDiv: HTMLDivElement|null = (this.$refs.innerDiv as HTMLDivElement);
        return (innerDiv != null) ?    innerDiv.offsetHeight : window.innerHeight;
    }

    private getCanvasWidth(): number {
        const vueCanvas: HTMLCanvasElement|null = (this.$refs.vueCanvas as HTMLCanvasElement);
        return (vueCanvas != null) ?    vueCanvas.width : this.getWidth();
    }

    private getCanvasHeight(): number {
        const vueCanvas: HTMLCanvasElement|null = (this.$refs.vueCanvas as HTMLCanvasElement);
        return (vueCanvas != null) ?    vueCanvas.height : this.getHeight();
    }

    private setupClipping(cornerRadius1: number, cornerRadius2: number): void {
        if (this.vueContext2d != null) {
            this.vueContext2d.beginPath(); {
                const x         = 0;
                const y         = 0;
                const width     = this.getWidth();
                const height    = this.getHeight() - 1; // Make it slightly shorter - avoid pixels on the lower edge

                // Upper left, start point
                this.vueContext2d.moveTo(x + cornerRadius1, y);

                // Upper line
                this.vueContext2d.lineTo(x + width - cornerRadius2, y);

                // Upper right corner
                this.vueContext2d.quadraticCurveTo(x + width, y, x + width, y + cornerRadius2);

                // Right line
                this.vueContext2d.lineTo(x + width, y + height - cornerRadius1);

                // Lower right corner
                this.vueContext2d.quadraticCurveTo(
                    x + width,
                    y + height,
                    x + width - cornerRadius1,
                    y + height);

                // Lower line
                this.vueContext2d.lineTo(x + cornerRadius2, y + height);

                // Lower left corner
                this.vueContext2d.quadraticCurveTo(x, y + height, x, y + height - cornerRadius2);

                // Left line
                this.vueContext2d.lineTo(x, y + cornerRadius1);

                // Upper left corner
                this.vueContext2d.quadraticCurveTo(x, y, x + cornerRadius1, y);
            }
            this.vueContext2d.closePath();

            this.vueContext2d.clip();
            this.haveSetupClipping = true;
        }
    }

    private setCanvasDimensions(): void {
        const canvas: HTMLCanvasElement | null  = (this.$refs.vueCanvas as HTMLCanvasElement);
        console.assert(canvas != null);
        if (canvas != null) {
            canvas.width    = this.getWidth();
            canvas.height   = this.getHeight();
        }
    }

    private setupVueContext2d(cornerRadius1: number, cornerRadius2: number): void {
        this.vueContext2d = (this.$refs.vueCanvas as HTMLCanvasElement).getContext("2d");
        console.assert(this.vueContext2d != null);

        if (this.vueContext2d != null) {
            if (this.haveSetupClipping) {
                this.vueContext2d.restore();    // Remove the previous clipping
                this.haveSetupClipping = false;
            }

            if (this.haveSetupClipping === false) {
                this.vueContext2d.save();
                this.setupClipping(cornerRadius1, cornerRadius2);
            }
        }
    }

    private setupCamera(): void {
        const aspectRatio: number = this.getWidth() / this.getHeight();
        this.camera = new THREE.PerspectiveCamera(
            this.CAMERA_FIELD_OF_VIEW,
            aspectRatio,
            this.CAMERA_NEAR_PLANE,
            this.CAMERA_FAR_PLANE);
        this.camera.position.z = this.CAMERA_POSITION_Z;
    }

    private setupThreeJsRenderer(): void {
        this.threeJsRenderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        this.threeJsRenderer.setClearColor(0x000000, 0);
        this.threeJsRenderer.setSize(this.getWidth(), this.getHeight());
        this.threeJsRenderer.gammaFactor     = 1.0;
        this.threeJsRenderer.outputEncoding  = THREE.GammaEncoding;
    }
}
export default ThreeBackgroundMixin;

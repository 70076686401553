
    import NavigationListGroup from "@/components/navigation-list-group.vue";
    import NavigationList from "@/components/navigation-list.vue";
    import { Component, Vue } from 'vue-property-decorator';

    @Component({
        components: {
            NavigationList,
            NavigationListGroup
        },
    })

    // *****************************************************************************************************************
    export default class NavigationDrawer extends Vue {
        // Fields

        private data() {
            // tslint:disable
            return {
                isVisible: false,

                backgroundImagePathAndName:
                    require("@/assets/images/navigation_bar_01.png"),

                // -----------------------------------------------------------------------------------------------------
                about1: [
                    { title: "Who I am",                        href: "/",                                                  icon: "fa-address-card" },
                ],
                about2: [
                    { title: "Leadership",                      href: "/about_leadership",                                  icon: "fa-lightbulb" },
                    { title: "How I Manage",                    href: "/about_my_management",                               icon: "fa-people-carry" },
                    { title: "My Skills",                       href: "/about_my_skills",                                   icon: "fa-keyboard" },
                    { title: "My Experience",                   href: "/about_my_experience",                               icon: "fa-space-shuttle" },
                    { title: "My References",                   href: "/about_my_references",                               icon: "fa-user-check" },
                ],
                about3: [
                    { title: "My Sports",                       href: "/about_my_sports",                                   icon: "sports_kabaddi" },
                ],

                // -----------------------------------------------------------------------------------------------------
                dev1: [
                    { title: "Installation List",               href: "/dev_installation_list",                             icon: "fa-tasks" },
                    { title: "VSCode Extensions & Settings",    href: "/dev_visual_studio_code_extensions_and_settings",    icon: "fa-tasks" },
                ],
                dev2: [
                    { title: "Frontend",                        href: "/dev_frontend",                                      icon: "fa-code" },
                    { title: "HTTP",                            href: "/dev_http",                                          icon: "fa-code" },
                ],
                dev3: [
                    { title: "Backend",                         href: "/dev_backend",                                       icon: "fa-code" },
                    { title: "Amazon Web Services",             href: "/dev_aws",                                           icon: "fab fa-aws" },
                    { title: "APIs",                            href: "/dev_apis",                                          icon: "fa-code" },
                    { title: "Databases",                       href: "/dev_databases",                                     icon: "fa-database" },
                    { title: "Docker",                          href: "/dev_docker",                                        icon: "fa-code" },
                    { title: "REST",                            href: "/dev_rest",                                          icon: "fa-code" },
                ],
                devBooks: [
                    { title: "Awesome List",                    href: "/books_awesome_list",                                icon: "fa fa-book" },
                    // { title: "Code Complete",                   href: "/books_code_complete",                               icon: "fa fa-book" },
                    // { title: "Design Patterns",                 href: "/books_design_patterns",                             icon: "fa fa-book" },
                    // { title: "Joy Inc",                         href: "/books_joy_inc",                                     icon: "fa fa-book" },
                    { title: "Radical Candor",                  href: "/books_radical_candor",                              icon: "fa fa-book" },
                    // { title: "Refactoring",                     href: "/books_refactoring",                                 icon: "fa fa-book" },
                    // { title: "The Chimp Paradox",               href: "/books_chimp_paradox",                               icon: "fa fa-book" },
                    // { title: "The Lean Startup",                href: "/books_the_lean_startup",                            icon: "fa fa-book" },
                    // { title: "The Mythical Man Month",          href: "/books_the_mythical_man_month",                      icon: "fa fa-book" },
                    // { title: "The Pragmatic Programmer",        href: "/books_the_pragmatic_programmer",                    icon: "fa fa-book" },
                ],
                dev4: [
                    { title: "Algorithms",                      href: "/dev_algorithms",                                    icon: "fa-lightbulb" },
                    { title: "Data Structures",                 href: "/dev_data_structures",                               icon: "fa-tree" },
                    { title: "Design Patterns",                 href: "/dev_design_patterns",                               icon: "fa-archway" },
                ],
                dev5: [
                    { title: "C++",                             href: "/dev_c_plus_plus",                                   icon: "fa-space-shuttle" },
                    { title: "C++ Quizzes",                     href: "/dev_c_plus_plus_quizzes",                           icon: "fa-question" },
                    { title: "C#",                              href: "/dev_c_sharp",                                       icon: "fa-fighter-jet" },
                    { title: "C# Quizzes",                      href: "/dev_c_sharp_quizzes",                               icon: "fa-question" },
                    { title: "TypeScript",                      href: "/dev_typescript",                                    icon: "fa-plane" },
                ],
                dev6: [
                    { title: "Bugs",                            href: "/dev_unity_bugs",                                    icon: "fa-cube" },
                    { title: "District - Docs",                 href: "/dev_unity_district_documentation",                  icon: "fa-cube" },
                    { title: "District - Limbo",                href: "/dev_unity_district_limbo",                          icon: "fa-cube" },
                    { title: "District - Map Room",             href: "/dev_unity_district_map_room",                       icon: "fa-cube" },
                    { title: "Links",                           href: "/dev_unity_links",                                   icon: "fa-cube" },
                    { title: "Media",                           href: "/dev_unity_media",                                   icon: "fa-cube" },
                    { title: "Optimizations",                   href: "/dev_unity_optimizations",                           icon: "fa-cube" },
                    { title: "Quizzes",                         href: "/dev_unity_quizzes",                                 icon: "fa-question" },
                    { title: "Quest Projects",                  href: "/dev_unity_quest_projects",                          icon: "fa-cube" },
                ],
                dev7: [
                    { title: "Blueprints",                      href: "/dev_unreal_blueprints",                             icon: "fa-question" },
                    { title: "Landscapes",                      href: "/dev_unreal_landscapes_and_open_worlds",             icon: "fa-question" },
                    { title: "Materials",                       href: "/dev_unreal_materials",                              icon: "fa-question" },
                    { title: "Animations",                      href: "/dev_unreal_skeletal_meshes_and_animations",         icon: "fa-question" },
                ],
                dev8: [
                    { title: "Gameplay",                        href: "/dev_gameplay",                                      icon: "fa-code" },
                    { title: "Hardware",                        href: "/dev_hardware",                                      icon: "fa-server" },
                    { title: "Oculus App Lab",                  href: "/dev_oculus_app_lab",                                icon: "fa-code" },
                ],

                // -----------------------------------------------------------------------------------------------------
                dronesLocations: [                    
                    { title: "Grand Union 1",                   href: "/drones_locations_grand_union_crescent_01",          icon: "fa-camera" },
                    { title: "Grand Union 2",                   href: "/drones_locations_grand_union_crescent_02",          icon: "fa-camera" },
                    { title: "Hackey Marshes 1",                href: "/drones_locations_hackney_marshes_01",               icon: "fa-camera" },
                    { title: "Haggerston 1",                    href: "/drones_locations_haggerston_01",                    icon: "fa-camera" },
                    { title: "London Fields 1",                 href: "/drones_locations_london_fields_01",                 icon: "fa-camera" },
                    { title: "London Fields 2",                 href: "/drones_locations_london_fields_02",                 icon: "fa-camera" },
                    { title: "Shoreditch 1",                    href: "/drones_locations_shoreditch_01",                    icon: "fa-camera" },
                    { title: "Shoreditch 2",                    href: "/drones_locations_shoreditch_02",                    icon: "fa-camera" },
                    { title: "Tenby 1",                         href: "/drones_locations_tenby_01",                         icon: "fa-camera" },
                    { title: "Tenby 2",                         href: "/drones_locations_tenby_02",                         icon: "fa-camera" },
                    { title: "Victoria Park 1",                 href: "/drones_locations_victoria_park_01",                 icon: "fa-camera" },
                ],
                dronesHardware: [
                    { title: "4G",                              href: "/drones_hardware_4g",                                icon: "fa-camera" },
                    { title: "Concept",                         href: "/drones_hardware_concept",                           icon: "fa-camera" },
                    { title: "Passenger",                       href: "/drones_hardware_passenger",                         icon: "fa-camera" },
                    { title: "Small",                           href: "/drones_hardware_small",                             icon: "fa-camera" },
                ],
                dronesItems: [
                    { title: "FPV Footage",                     href: "/drones_fpv_footage",                                icon: "fa-battery-full" },
                ],

                // -----------------------------------------------------------------------------------------------------
                economy1: [
                    { title: "The Economy",                     href: "/economy_economy",                                   icon: "fa-balance-scale-right" },
                    { title: "Cryptocurrencies",                href: "/economy_cryptocurrencies",                          icon: "fab fa-bitcoin" },
                    { title: "HS2 Costs",                       href: "/economy_hs2",                                       icon: "fa-subway" },
                    { title: "Precious Metals",                 href: "/economy_precious_metals",                           icon: "fa-coins" },
                    { title: "Property",                        href: "/economy_property",                                  icon: "fa fa-key" },
                    { title: "Stock Market",                    href: "/economy_stock_market",                              icon: "fa-chart-line" },
                ],

                // -----------------------------------------------------------------------------------------------------
                future1: [
                    { title: "Automation",                      href: "/future_automation",                                 icon: "fa-industry" },
                    { title: "Covid-19",                        href: "/future_covid_19",                                   icon: "fa-viruses" },
                    { title: "Space",                           href: "/future_space",                                      icon: "fa-rocket" },
                    { title: "Telepresence",                    href: "/future_telepresence",                               icon: "fa-glasses" },
                    { title: "Tokamak Fusion",                  href: "/future_tokamak_fusion",                             icon: "fas fa-atom" },
                ],

                // -----------------------------------------------------------------------------------------------------
                people1: [
                    { title: "Hierarchy of Needs",              href: "/people_hierarchy_of_needs",                         icon: "mood" },
                    { title: "Population & Housing",            href: "/people_population_and_housing",                     icon: "fa-users" },
                    { title: "Remuneration & Tax",              href: "/people_remuneration_and_tax",                       icon: "fa-money-bill-wave" },
                ],

                // -----------------------------------------------------------------------------------------------------
                xr1: [
                    { title: "Headsets",                        href: "/xr_headsets",                                       icon: "fa-vr-cardboard" },
                    { title: "Configurations",                  href: "/xr_configurations",                                 icon: "settings_input_hdmi" },
                ],
                xr2: [
                    { title: "District 1",                      href: "/xr_locations_district_01",                          icon: "fa-camera" },
                    { title: "District 2",                      href: "/xr_locations_district_02",                          icon: "fa-camera" },
                ],
                xr3: [
                    { title: "Foveated Rendering",              href: "/xr_foveated_rendering",                             icon: "fa-eye" },
                    { title: "Immersion",                       href: "/xr_immersion",                                      icon: "menu_book" },
                    { title: "Photospheres",                    href: "/xr_photospheres",                                   icon: "menu_book" },
                    { title: "Wave Guides",                     href: "/xr_waveguides",                                     icon: "fa-glasses" },
                ],
                xr4: [
                    { title: "My Articles",                     href: "/xr_my_articles",                                    icon: "fa-user-graduate" },
                    { title: "Operating Systems",               href: "/xr_operating_systems",                              icon: "fa-laptop-code" },
                    { title: "Timeline",                        href: "/xr_timeline",                                       icon: "fa-calendar-alt" },
                ],
            };
            // tslint:enable
        }
    }

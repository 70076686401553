
    import HexagonsThree from "@/components/hexagons/hexagons-three.vue";
    import HexagonsTwo from "@/components/hexagons/hexagons-two.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            HexagonsThree,
            HexagonsTwo,
        },
    })

    // *****************************************************************************************************************
    export default class MyToolsCarousel extends Vue {
        // Static fields
        private static sheetHue: number                             = 120;

        // Read only fields
        private readonly NON_MOBILE_WIDTH_THRESHOLD: number         = 641;

        private readonly CAROUSEL_HEIGHT_ON_MOBILE: number          = 336;
        private readonly CAROUSEL_HEIGHT_ON_NON_MOBILE: number      = 512;

        private readonly SHEET_HUE_STEP_AMOUNT: number              = 60;
        private readonly SHEET_HUE_RANGE: number                    = 360;

        // Methods -----------------------------------------------------------------------------------------------------
        private data() {
            return {
                imagePaths: [
                    require("@/assets/images/confluence.png"),
                    require("@/assets/images/jira.png"),
                    require("@/assets/images/slack.png"),
                    require("@/assets/images/skype.png"),
                    require("@/assets/images/google_account.png"),

                    require("@/assets/images/github.png"),
                    require("@/assets/images/bitbucket.png"),
                    require("@/assets/images/gitkraken.png"),
                    require("@/assets/images/sourcetree.png"),
                    require("@/assets/images/beyond_compare.png"),

                    require("@/assets/images/jet_brains_rider.png"),
                    require("@/assets/images/visual_studio_code.png"),
                    require("@/assets/images/visual_studio_2019.png"),
                    require("@/assets/images/android_studio.png"),
                    require("@/assets/images/notepad_plus_plus.png"),

                    require("@/assets/images/c_sharp.png"),
                    require("@/assets/images/c_plus_plus.png"),
                    require("@/assets/images/unity.png"),
                    require("@/assets/images/prime31.png"),
                    require("@/assets/images/vuforia.png"),
                    require("@/assets/images/typescript.png"),
                    require("@/assets/images/glsl.png"),

                    require("@/assets/images/yarn.png"),
                    require("@/assets/images/vue.png"),
                    require("@/assets/images/pug.png"),
                    require("@/assets/images/vuetify.png"),
                    require("@/assets/images/webpack.png"),
                    require("@/assets/images/aws.png"),
                    require("@/assets/images/mongodb.png"),

                    require("@/assets/images/maya.png"),
                    require("@/assets/images/photoshop.png"),
                    require("@/assets/images/3ds_max.png"),
                    require("@/assets/images/lucid_chart.png"),
                    require("@/assets/images/draw_io.png"),

                    require("@/assets/images/audacity.png"),
                    require("@/assets/images/you_tube.png"),
                ],
            };
        }

        private getCarouselHeight(): number {
            if (this.$vuetify.breakpoint.width >= this.NON_MOBILE_WIDTH_THRESHOLD ) {
                return this.CAROUSEL_HEIGHT_ON_NON_MOBILE;
            }

            return this.CAROUSEL_HEIGHT_ON_MOBILE;
        }

        private getSheetColor(): string {
            // Increment and then wrap the sheetHue value within a range
            MyToolsCarousel.sheetHue += this.SHEET_HUE_STEP_AMOUNT;
            MyToolsCarousel.sheetHue %= this.SHEET_HUE_RANGE;

            return "hsl(" + MyToolsCarousel.sheetHue + ", 20%, 40%)";
        }
    }

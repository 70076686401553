
    import NavigationList from "@/components/navigation-list.vue";
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {
            NavigationList
        },
    })

    // *****************************************************************************************************************
    export default class NavigationListGroup extends Vue {
        // Properties
        @Prop() private readonly itemTitle!: string;
        @Prop() private readonly items!: object[];
    }

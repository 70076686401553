import '@fortawesome/fontawesome-free/css/all.css'; // Ensure you are using css-loader
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    // tslint:disable
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
    },

    theme: {
        dark: true,
        themes: {
            dark: {
                primary:        '#e57200',
                secondary:      '#2196f3',
                accent:         '#673ab7',
                error:          '#f44336',
                warning:        '#9c27b0',
                info:           '#009688',
                success:        '#4caf50',
            },
        },
    },
    // tslint:enable
});


    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class SheetImage extends Vue {
        // Properties
        @Prop() private readonly imagePathAndName!: string;

        // Data function
        public data() {
            return {
            };
        }
    }

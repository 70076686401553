
    import { CloudsAboveShader } from '@/components/background-3d/CloudsAboveShader';
    import { OceanFastShader } from '@/components/background-3d/OceanFastShader';
    import { OceanShader } from '@/components/background-3d/OceanShader';
    import ThreeBackgroundMixin from '@/mixins/three-background-mixin';
    import { GraphicsUtils } from '@/ts/utils/GraphicsUtils';
    import * as THREE from 'three';
    import { Component, Mixins, Prop } from 'vue-property-decorator';

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class Sheet3d extends Mixins(ThreeBackgroundMixin) {
        // Properties
        @Prop() private readonly iconPathAndName!: string;
        @Prop() private readonly heading!: string;

        // Readonly
        private readonly CORNER_RADIUS_1: number                            =   0;
        private readonly CORNER_RADIUS_2: number                            =   0;

        private readonly PLANE_WIDTH_SEGMENTS: number                       =    1;

        private readonly shader                                             = new OceanShader();

        private readonly uniforms = {
            iGlobalTime: { type: 'f',   value: 0.1 },
            iResolution: { type: 'v2',  value: new THREE.Vector2() },
        };

        private readonly clock                                              = new THREE.Clock();

        public mounted() {
            this.setup(this.CORNER_RADIUS_1, this.CORNER_RADIUS_2);

            this.setupShaderUniforms();
            GraphicsUtils.setShader(this.shader, this.shaderMaterial);
            this.initializeShaderMaterial(this.shader);
            this.setupBackgroundMesh();
            this.setupScene();

            const selfRequestingRender = () => {
                this.animateUniforms();
                if (this.shouldResize()) {
                    this.resize(this.CORNER_RADIUS_1, this.CORNER_RADIUS_2);
                    this.setupShaderUniforms();
                }
                if (this.threeJsRenderer != null &&
                    this.scene != null &&
                    this.camera != null) {
                    this.threeJsRenderer.render(this.scene, this.camera);
                    this.drawThreeJsCanvasOnToVueCanvas();
                }
                requestAnimationFrame(selfRequestingRender);
            };
            selfRequestingRender();     // Kick off the first call to selfRequestingRender()
        }

        // Implementation ----------------------------------------------------------------------------------------------
        private setupShaderUniforms(): void {
            this.uniforms.iResolution.value.x   = this.getWidth();
            this.uniforms.iResolution.value.y   = this.getHeight();
            this.shader.uniforms                = this.uniforms;
        }

        private setupBackgroundMesh(): void {
            this.backgroundMesh = new THREE.Mesh(
                new THREE.PlaneBufferGeometry(window.innerWidth, window.innerHeight, this.PLANE_WIDTH_SEGMENTS),
                this.shaderMaterial);
        }

        private setupScene(): void {
            this.scene = new THREE.Scene();
            if (this.backgroundMesh != null) {
                this.scene.add(this.backgroundMesh);
            }
        }

        private animateUniforms(): void {
            this.uniforms.iGlobalTime.value += this.clock.getDelta();
        }
    }


    import CarouselEducationCard from "@/components/carousel_cards/carousel_education_card.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            CarouselEducationCard,
        },
    })

    // *****************************************************************************************************************
    export default class MyEducation extends Vue {
        // Data function
        public data() {
            return {

                cardStyleString:
                    "background-image: linear-gradient(180deg, hsl(0, 10%, 40%), hsl(0, 0%, 22%)); border-radius: 8px; margin: 8px",

                degreeImagePathAndName:
                    require("@/assets/images/degree.png"),

                middlesexUniversityImagePathAndName:
                    require("@/assets/images/middlesex_university.png"),

                middlesexUniversity00ImagePathAndName:
                    require("@/assets/images/middlesex_university_00.png"),

                middlesexUniversity01ImagePathAndName:
                    require("@/assets/images/middlesex_university_02.png"),

                middlesexUniversity02ImagePathAndName:
                    require("@/assets/images/middlesex_university_01.png"),
            };
        }
    }

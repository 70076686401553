
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class Sheet extends Vue {
        // Const
        private readonly DEFAULT_FROM_HUE: string               = "240";
        private readonly DEFAULT_TO_HUE: string                 = "0";

        // Properties
        @Prop() private readonly fromHue!: string;
        @Prop() private readonly toHue!: string;
        @Prop() private readonly iconPathAndName!: string;
        @Prop() private readonly heading!: string;

        // Data function
        public data() {
            return {
            };
        }

        // We use a computed property in case fromHue has not been set, but we still want a default value, but cannot
        // safely apply one to this.fromHue, as it will conflict with what the parent component is setting.
        private get safeFromHue(): string {
            if (this.fromHue == null) {
                return this.DEFAULT_FROM_HUE;
            }

            return this.fromHue;
        }

        // We use a computed property in case toHue has not been set, but we still want a default value, but cannot
        // safely apply one to this.toHue, as it will conflict with what the parent component is setting.
        private get safeToHue(): string {
            if (this.toHue == null) {
                return this.DEFAULT_TO_HUE;
            }

            return this.toHue;
        }

        private get styleString(): string {
            return "background-image: linear-gradient(165deg, " +
                "hsla(" + this.safeFromHue + ", 20%, 50%, 0.5), " +
                "hsla(" + this.safeToHue + ", 20%, 20%, 0.5))";
        }
    }

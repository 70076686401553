
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class AppFooter extends Vue {
        // Fields
        private readonly links = [
            { icon: 'fab fa-linkedin',      url: 'https://www.linkedin.com/in/danieldobson/' },
            { icon: 'fab fa-github',        url: 'https://github.com/Daniel-Dobson' },
        ];

        // Methods -----------------------------------------------------------------------------------------------------
        private data() {
            return {

                linesImagePathAndName:
                    require("@/assets/images/contact_card_lines.png"),
            };
        }
    }

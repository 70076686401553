
    import AppBar from "@/components/app-bar.vue";
    import AppFooter from "@/components/app-footer.vue";
    import Background3d from "@/components/background-3d/background-3d.vue";
    import CardWithLighting from "@/components/card-with-lighting.vue";
    import NavigationDrawer from "@/components/navigation-drawer.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            App,
            AppBar,
            AppFooter,
            Background3d,
            CardWithLighting,
            NavigationDrawer
        },
    })

    // *****************************************************************************************************************
    export default class App extends Vue {
    }

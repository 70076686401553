
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class Heading extends Vue {
        // Properties
        @Prop() private readonly text!: string;
        @Prop() private readonly iconPathAndName!: string;

        // Methods -----------------------------------------------------------------------------------------------------
        private data() {
            return {
                beamImagePathAndName:
                    require("@/assets/images/beam_01.png"),
            };
        }
    }

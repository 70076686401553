
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class MySkillsMap extends Vue {
        // Data function
        public data() {
            return {
                skillsMapPathAndName:
                    require("@/assets/images/skills_map.svg"),

                systemsIconPathAndName:
                    require("@/assets/images/icon_systems.png")
            };
        }
    }

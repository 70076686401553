import * as THREE from 'three';

// *********************************************************************************************************************
export class GraphicsUtils {
    // Methods
    public static setShader(shader: THREE.Shader, shaderMaterial: THREE.ShaderMaterial): void {
        // Create the options object to send to ShaderMaterial
        const shaderObject = {
            fragmentShader:     shader.fragmentShader,
            lights:             true,
            uniforms:           null,
            vertexShader:       shader.vertexShader,
        };

        // Add uniforms if present
        if ('uniforms' in shader) {
            const lightsKey = 'lights';

            // Using UniformUtils will clone the shader files uniforms
            shaderObject.uniforms = THREE.UniformsUtils.merge([
                THREE.UniformsLib[lightsKey],
                shader.uniforms,
            ]);
        }

        // Set this new material on the mesh
        shaderMaterial = new THREE.ShaderMaterial(shaderObject);

        // Add the original uniforms here
        shaderMaterial.uniforms = shader.uniforms;
    }
}


    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class SheetMap extends Vue {
        // Properties
        @Prop() private readonly latitude!: number;
        @Prop() private readonly longitude!: number;

        @Prop() private readonly zoom!: number;

        private get getStyle(): string {
            return "width: 100vw; height: 672px";
        }

        private get center(): object {
            return {
                lat: this.latitude,
                lng: this.longitude
            };
        }

        private get options(): object {
            return {
                disableDefaultUi:   true,
                fullscreenControl:  false,
                mapTypeControl:     false,
                rotateControl:      false,
                scaleControl:       false,
                streetViewControl:  false,
                zoomControl:        false
            };
        }
    }

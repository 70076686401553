<!--Panorama viewer pannellum wrap.-->
<template lang="pug">
    div.sheet-pannellum
        div.info {{ info }}
        div.default-slot
            <slot/>
</template>

<script>
    import 'pannellum';
    import 'pannellum/build/pannellum.css';

    import _debounce from 'lodash.debounce';

    const DEFAULT_AUTO_ROTATE_SPEED = -3;
    const AUTO_ROTATE_DELAY         = 3000;
    const PITCH_LIMIT               = 90;

    export default {

        // tslint:disable
        props: {
            debug:          { type: Boolean, default: false },
            src:            { type: [String, Object], required: true },
            preview:        { type: String, default: '' },
            autoLoad:       { type: Boolean, default: true },
            autoRotate:     { type: [Number, Boolean], default: true },
            orientation:    { type: Boolean, default: false },
            showZoom:       { type: Boolean, default: false },
            showFullscreen: { type: Boolean, default: false },
            compass:        { type: Boolean, default: false },
            hotSpots:       { type: Array, default: () => [] },
            hfov:           { type: Number, default: 60 },
            minHfov:        { type: Number, default: 45 },
            maxHfov:        { type: Number, default: 90 },
            yaw:            { type: Number, default: 0 },
            pitch:          { type: Number, default: 0 },
        },
        // tslint:enable

        data() {
            return {
                info:       '',
                rafId:      -1,
                viewer:     null,
            };
        },

        computed: {
            srcOption() {
                return typeof this.src === 'string' ? {
                    panorama: this.src,
                } : {
                    cubeMap: [
                        this.src.pz,
                        this.src.px,
                        this.src.nz,
                        this.src.nx,
                        this.src.py,
                        this.src.ny,
                    ]
                };
            },
        },

        watch: {
            src(val) {
                this.$el.innerHTML = '';
                this.$nextTick(this.load);
            },
/*
            hfov(val) {
                if (this.viewer) {
                    this.viewer.setHfov(val, false);
                }
            },

            yaw(val) {
                if (this.viewer) {
                    this.viewer.setYaw(val, false);
                }
            },

            pitch(val) {
                if (this.viewer) {
                    this.viewer.setPitch(val, false);
                }
            },

            maxHfov(val) {
                if (this.viewer) {
                    this.viewer.setHfovBounds([this.minHfov, this.maxHfov]);
                }
            },

            minHfov(val) {
                if (this.viewer) {
                    this.viewer.setHfovBounds([this.minHfov, this.maxHfov]);
                }
            },

            autoRotate(val) {
                if (val) {
                    this.viewer.startAutoRotate();
                } else {
                    this.viewer.stopAutoRotate();
                    if (this.orientation) {
                        this.viewer.startOrientation();
                    }
                }
            },

            orientation(val) {
                if (val) {
                    this.viewer.startOrientation();
                } else {
                    this.viewer.stopOrientation();
                    if (this.autoRotate) {
                        this.viewer.startAutoRotate();
                    }
                }
            },
            */
        },

        mounted() {
            this.load();
            this.rafId = window.requestAnimationFrame(this.loop);
        },

        beforeDestroy() {
            this.viewer.destroy();
            window.cancelAnimationFrame(this.rafId);
        },

        methods: {
            load() {
                // tslint:disable
                const options = {
                    type:                       "equirectangular",
                    autoLoad:                   true,
                    autoRotate:                 DEFAULT_AUTO_ROTATE_SPEED,
                    orientationOnByDefault:     false,
                    compass:                    false,
                    hotSpots:                   false,
                    preview:                    false,
                    hfov:                       this.hfov,
                    yaw:                        this.yaw,
                    pitch:                      this.pitch,
                    minHfov:                    this.minHfov,
                    maxHfov:                    this.maxHfov,
                    // Newly added
                    mouseZoom:                  false,
                    showControls:               false, 
                    compass:                    false,
                    keyboardZoom:               false,
                    mouseZoom:                  false,
                    draggable:                  false,
                    autoRotateInactivityDelay:  0
                };
                // tslint:enable

                Object.assign(options, this.srcOption);
                this.viewer = window.pannellum.viewer(this.$el, options);

                this.viewer.on('load', () => {
                    this.$emit('load');
                });

                this.viewer.on('error', (err) => {
                    this.$emit('error', err);
                });

                /*
                if (this.showZoom === false) {
                    const el = this.$el.querySelector('.pnlm-zoom-controls');
                    if (el) el.style.display = 'none';
                }

                if (this.showFullscreen === false) {
                    const el = this.$el.querySelector('.pnlm-fullscreen-toggle-button');
                    if (el) el.style.display = 'none';
                }
                */
            },

            loop() {
                this.rafId  = window.requestAnimationFrame(this.loop);

                /*
                const hfov  = this.viewer.getHfov();
                const yaw   = this.viewer.getYaw();
                let pitch   = this.viewer.getPitch();

                if (pitch > PITCH_LIMIT) {
                    pitch = PITCH_LIMIT;
                } else if (pitch < -PITCH_LIMIT) {
                    pitch = -PITCH_LIMIT;
                }
                if (hfov !== this.hfov) {
                    this.$emit('update:hfov', hfov);
                }
                if (yaw !== this.yaw) {
                    this.$emit('update:yaw', yaw);
                }
                if (pitch !== this.pitch) {
                    this.$emit('update:pitch', pitch);
                }
                */
            },

            /*
            onMouseUp() {
                if (this.debug) {
                    this.info += ' mu';
                }
                this.debounceRotate();
            },

            onTouchMove() {
                if (this.debug) {
                    this.info += ' tm';
                }
            },

            onTouchEnd() {
                if (this.debug) {
                    this.info += ' te';
                }
                this.debounceRotate();
            },

            debounceRotate: _debounce(function() {
                // Priority of orientation is higher
                if (this.orientation) {
                    this.viewer.startOrientation();
                } else {
                    if (this.autoRotate) {
                        this.viewer.startAutoRotate();
                    }
                }
            }, AUTO_ROTATE_DELAY),
            */
        },
    };
</script>

<style>
    .pnlm-ui .pnlm-about-msg {
        display: none !important;
    }

    .pnlm-ui .pnlm-orientation-button {
        display: none !important;
    }
</style>

<style scoped>
    .sheet-pannellum {
        position:           relative;
    }

    .info {
        position:           absolute;
        background-color: hsla(0, 0%, 100%, 0.5);
        top:                0;
        left:               0;
        width:              100%;
        z-index:            2;
    }

    .default-slot {
        position:           absolute;
        left:               0;
        bottom:             0;
        z-index:            2;
    }
</style>
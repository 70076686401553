
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class SheetVideo extends Vue {
        // Properties
        @Prop() private readonly url!: string;
    }
